
































































































































































import { defineComponent } from "@vue/composition-api";
import { parse, subMonths, subWeeks, subYears, yearsToMonths } from "date-fns";
import type Highcharts from "highcharts";
import { mapStores } from "pinia";
import type { IomodelsCoachappAfgenomenMeting } from "@/lib/backend/club.api";
import { DEFAULT_TRACKING } from "@/lib/constants/misc";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import { capitalize } from "@/utils/capitalize";
import { getAge, getDateFromDatum, getDayMonthYear } from "@/utils/date";

type MetingSlug = keyof Omit<IomodelsCoachappAfgenomenMeting, "metingId" | "metingDatum" | "lidId">;

type MetingItem = {
	slug: MetingSlug;
	post?: string;
};

type GraphPeriod = "sixWeeks" | "sixMonths" | "oneYear" | "allTime";

type Data = {
	loading: boolean;
	graphPeriod: GraphPeriod;
	activeMetingSlug: MetingSlug;
	metingItems: MetingItem[];
	ranges: Record<
		string,
		Record<
			MetingSlug,
			Array<{
				age: [number, number];
				range: [number, number];
				showAgeRange?: boolean;
			}>
		>
	>;
	chartOptions: Highcharts.Options;
};

type LaatsteMeting = MetingItem & {
	latest: string | number | null | undefined;
};

export default defineComponent({
	name: "PfgGebruikerMetingen",
	props: {},
	data(): Data {
		return {
			loading: false,
			graphPeriod: "sixMonths",
			activeMetingSlug: "gewicht",
			metingItems: [
				{
					slug: "gewicht",
					post: "(kg)",
				},
				{
					slug: "vetpercentage",
					post: "(%)",
				},
				{
					slug: "bmi",
				},
				{
					slug: "conditie",
				},
				{
					slug: "muscleMass",
					post: "(%)",
				},
				{
					slug: "waistCircumference",
					post: "(cm)",
				},
				{
					slug: "hartslag",
					post: "(bpm)",
				},
				{
					slug: "onderdruk",
				},
				{
					slug: "bovendruk",
				},
				{
					slug: "lengte",
					post: "(cm)",
				},
			],
			ranges: {
				m: {
					vetpercentage: [
						{
							age: [0, 29],
							range: [8, 18],
						},
						{
							age: [30, 39],
							range: [11, 20],
						},
						{
							age: [40, 59],
							range: [12, 22],
						},
						{
							age: [60, 100],
							range: [13, 25],
						},
					],
					bmi: [
						{
							range: [15.5, 25],
							age: [0, 100],
							showAgeRange: false,
						},
					],
					conditie: [
						{
							age: [20, 24],
							range: [44, 50],
						},
						{
							age: [25, 29],
							range: [43, 48],
						},
						{
							age: [30, 34],
							range: [41, 45],
						},
						{
							age: [35, 39],
							range: [39, 43],
						},
						{
							age: [40, 44],
							range: [36, 41],
						},
						{
							age: [45, 49],
							range: [35, 39],
						},
						{
							age: [50, 54],
							range: [37, 41],
						},
						{
							age: [55, 59],
							range: [31, 34],
						},
						{
							age: [60, 100],
							range: [29, 32],
						},
					],
					muscleMass: [{ range: [0, 100], age: [0, 100], showAgeRange: false }],
					onderdruk: [
						{
							age: [0, 100],
							range: [80, 90],
							showAgeRange: false,
						},
					],
					bovendruk: [
						{
							age: [0, 100],
							range: [120, 140],
							showAgeRange: false,
						},
					],
					lengte: [],
					hartslag: [],
					gewicht: [],
					waistCircumference: [],
				},
				f: {
					vetpercentage: [
						{
							age: [0, 29],
							range: [20, 27],
						},
						{
							age: [30, 39],
							range: [22, 31],
						},
						{
							age: [40, 59],
							range: [23, 34],
						},
						{
							age: [60, 100],
							range: [24, 36],
						},
					],
					bmi: [
						{
							age: [0, 100],
							range: [15.5, 25],
							showAgeRange: false,
						},
					],
					conditie: [
						{
							age: [0, 24],
							range: [37, 41],
						},
						{
							age: [25, 29],
							range: [36, 40],
						},
						{
							age: [30, 34],
							range: [34, 37],
						},
						{
							age: [35, 39],
							range: [32, 35],
						},
						{
							age: [40, 44],
							range: [30, 33],
						},
						{
							age: [45, 49],
							range: [28, 31],
						},
						{
							age: [50, 54],
							range: [26, 29],
						},
						{
							age: [55, 59],
							range: [24, 27],
						},
						{
							age: [60, 100],
							range: [22, 24],
						},
					],
					muscleMass: [{ range: [0, 100], age: [0, 100], showAgeRange: false }],
					onderdruk: [
						{
							age: [0, 100],
							range: [80, 90],
							showAgeRange: false,
						},
					],
					bovendruk: [
						{
							age: [0, 100],
							range: [120, 140],
							showAgeRange: false,
						},
					],
					lengte: [],
					hartslag: [],
					gewicht: [],
					waistCircumference: [],
				},
			},
			chartOptions: {
				xAxis: [{}],
			},
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
		isTracking(): boolean {
			return this.gebruikerStore?.tracking?.measurements ?? DEFAULT_TRACKING;
		},
		metingen(): IomodelsCoachappAfgenomenMeting[] {
			return this.gebruikerStore.metingen?.metingen ?? [];
		},
		laatsteMeting(): string {
			if (!this.gebruikerStore.metingen.metingen) return "";

			const metingDatum = this.gebruikerStore.metingen.metingen[0].metingDatum;

			if (!metingDatum) return "";

			return getDayMonthYear(getDateFromDatum(metingDatum));
		},
		laatsteMetingen(): LaatsteMeting[] {
			if (!this.metingen) return [];
			return this.metingItems.map<LaatsteMeting>((metingItem): LaatsteMeting => {
				(metingItem as LaatsteMeting).latest = this.metingen?.[0]?.[metingItem.slug];

				return metingItem as LaatsteMeting;
			});
		},
		ageRange(): string {
			const range = this.getRange();

			if (range) return `${range.age[0]}-${range.age[1]}`;
			else return "";
		},
		metingText(): (value: string | number | null | undefined) => string {
			return function (value: string | number | null | undefined) {
				if (!value) {
					return "-";
				}

				return String(value);
			};
		},
	},
	watch: {
		$route: "check",
		activeMetingSlug: "setGraph",
		graphPeriod: "setGraph",
		"gebruikerStore.metingen": "setGraph",
	},
	async created() {
		await this.check();
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();
			await this.gebruikerStore.getTracking();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getMetingen();
			await this.gebruikerStore.getDoel();
		},
		editOneMeting(metingId: number) {
			this.$router.push({
				name: "Gebruiker meting",
				params: {
					id: String(this.gebruikerStore.id),
					metingId: String(metingId),
				},
			});
		},
		async removeOneMeting(metingId: number) {
			try {
				this.popupStore.open({
					title: "Let op!",
					body: "Weet je zeker dat je deze meting wilt verwijderen?",
					buttons: {
						cancel: "Annuleren",
						confirm: "Verwijderen",
					},
					callback: async () => await this.gebruikerStore.removeMeting(metingId),
				});
			} catch (error) {
				this.popupStore.showError(
					`Er ging iets mis bij het verwijderen van de meting: ${error}.<br/>Probeer het later nog eens.`,
				);
			}
		},
		getRange() {
			if (!this.gebruikerStore.basicInfo || !this.activeMetingSlug) return;

			if (!this.ranges[this.gebruikerStore.basicInfo?.geslacht][this.activeMetingSlug]) return;

			const range = this.ranges[this.gebruikerStore.basicInfo.geslacht][this.activeMetingSlug];

			if (!range[0]?.showAgeRange) return range[0];

			const age = getAge(this.gebruikerStore.basicInfo.geboorteDatum ?? "");
			const ageRange = range.find((range) => age >= range.age[0] && age <= range.age[1]);

			return ageRange;
		},
		async setGraph() {
			const hasDoel = this.gebruikerStore.doel.doel?.slug === this.activeMetingSlug;

			const range = this.getRange();

			const options: Highcharts.Options = {
				chart: {
					type: "areaspline",
					zoomType: "x",
				},
				xAxis: {
					title: {
						text: "Metingen",
					},
					type: "datetime",
					dateTimeLabelFormats: {
						day: "%e %b %Y",
						week: "%e %b %Y",
						month: "%e %b %Y",
						year: "%Y",
					},
					minPadding: 0.1,
					max: Date.now(),
					...(() => {
						switch (this.graphPeriod) {
							case "sixWeeks": {
								const weeks = 6;

								return {
									min: subWeeks(new Date(), weeks).getTime(),
									tickAmount: weeks,
								};
							}
							default:
							case "sixMonths": {
								const months = 6;

								return {
									min: subMonths(new Date(), months).getTime(),
									tickAmount: months,
								};
							}
							case "oneYear": {
								const years = 1;

								return {
									min: subYears(new Date(), years).getTime(),
									tickAmount: yearsToMonths(1),
								};
							}
							case "allTime": {
								return { min: null, tickAmount: 12 };
							}
						}
					})(),
				},
				yAxis: {
					title: {
						text: (() => {
							switch (this.activeMetingSlug) {
								case "muscleMass":
									return "Spiermassa";
								case "waistCircumference":
									return "Buikomtrek";
								default:
									return capitalize(this.activeMetingSlug);
							}
						})(),
						align: "left",
					},
					plotLines: [
						...(range ?? { range: [] })?.range.map((value) => ({
							color: "#2ad290",
							dashStyle: "shortdash",
							width: 2,
							value: value,
						})),
						...(hasDoel
							? [
									{
										color: "#262261",
										dashStyle: "shortdash",
										width: 2,
										value: this.gebruikerStore.doel.doel?.amount ?? 0,
									},
								]
							: []),
					],
					min: 0,
					minPadding: 0.25,
					max: 100,
					maxPadding: 0.25,
				},
				plotOptions: {
					series: {
						pointStart: 23,
					},
					areaspline: {
						fillColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1,
							},
							stops: [
								[0, "rgba( 212, 20, 90, 0.25 )"],
								[1, "rgba( 255, 255, 255, 0 )"],
							],
						},
						marker: {
							radius: 2,
						},
						lineWidth: 4,
						threshold: null,
					},
				},
				tooltip: {
					headerFormat: '<div style="font-size: 16px;">{point.key}</div>',
					xDateFormat: "%e %b %Y",
				},
				series: [
					{
						name: (() => {
							switch (this.activeMetingSlug) {
								case "muscleMass":
									return "Spiermassa";
								case "waistCircumference":
									return "Buikomtrek";
								default:
									return capitalize(this.activeMetingSlug);
							}
						})(),
						data: this.metingen.reduce<Array<[number, number]>>(
							(acc, meting) => [
								...(meting[this.activeMetingSlug]
									? [
											[
												parse(meting.metingDatum || "", "dd-MM-yyyy", new Date()).getTime(),
												meting[this.activeMetingSlug] || 0,
											] as [number, number],
										]
									: []),
								...acc,
							],
							[],
						),
					},
					...(range
						? [
								{
									color: "#2ad290",
									dashStyle: "shortdash",
									name: "Leeftijdgroep streven",
								},
							]
						: []),
					// Target line
					...(hasDoel
						? [
								{
									color: "#262261",
									dashStyle: "shortdash",
									name: "Streefdoel",
								},
							]
						: []),
				],
				responsive: {
					rules: [
						{
							condition: {
								minWidth: 560, // minus padding of main container = 40
							},
							chartOptions: {
								chart: {
									height: 320,
								},
							},
						},
						{
							condition: {
								minWidth: 760, // minus padding of main container = 40
							},
							chartOptions: {
								chart: {
									height: 400,
								},
							},
						},
					],
				},
			};

			const values: number[] = this.metingen
				.flatMap((meting) => meting[this.activeMetingSlug])
				.filter(Boolean);

			if (range?.range) {
				values.push(...range.range);
			}

			(options.yAxis as Highcharts.AxisOptions).min =
				Math.min(...values) - Math.min(...values) * 0.02;
			(options.yAxis as Highcharts.AxisOptions).max =
				Math.max(...values) + Math.max(...values) * 0.02;

			this.chartOptions = options;
			return options;
		},
		async changeTracking(active: boolean) {
			await this.gebruikerStore.updateTracking({
				value: active,
				type: "metingen",
			});

			if (active) await this.getRequiredInfo();
			else await this.gebruikerStore.getMetingen();
		},
		getAge,
		capitalize,
	},
});
